import moment from 'moment';
import 'moment-timezone';
import { displayPrice, isMatchEstimate, isObject } from '../../../lib/Utility';
import { v4 } from 'uuid';
import {
  ContactData,
  Match,
  MatchAccessorialData,
  MatchData,
  MatchStop,
  MatchStopData,
} from '../../../lib/actions/MatchAction';
import store, { useAppDispatch } from '../../../lib/store';
import {
  createEstimate,
  fetchAccessorials,
  selectEstimateMatch,
  selectEstimateBoxTruckAgreement,
  updateEstimate,
  selectVehicleClass,
} from '../../../lib/reducers/estimateSlice';

import { matchScheduleSchema, ScheduleValues } from '../MatchScheduler';
import { Formik, yupToFormErrors } from 'formik';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { useSelector } from 'react-redux';
import { ShipTabProps } from '../../../screens/ShipScreen';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePrevious } from '../../../lib/Hooks';
import {
  buildCargoStopItemValues,
  cargoSchema,
  cargoStopItemSchema,
  cargoStopSchema,
  CargoStopValues,
  CargoValues,
} from './Cargo';
import { FormikChangeHelpers } from '../../form/FormikOnChange';
import { MatchCsvUploader } from '../MatchCsvUploader';
import {
  fetchFeatureFlag,
  fetchUserContracts,
  selectUser,
} from '../../../lib/reducers/userSlice';
import { selectPlatform } from '../../../lib/reducers/appSlice';
import EstimateForm from '../EstimateForm';
import { Shipper } from '../../../lib/actions/ShipperAction';
import { User } from '../../../lib/actions/UserAction';

export type EstimateValues =
  | {
      stops: EstimateStopValues[];
      box_truck_agreement?: boolean;
      sender?: ContactData | null;
      address_name?: string | null;
    } & (PickOptional<
      MatchData,
      | 'origin_address'
      | 'origin_place_id'
      | 'self_sender'
      | 'preferred_driver_id'
      | 'accessorials'
      | 'contract_id'
      | 'platform'
      | 'po'
      | 'route_identifier'
      | 'bill_of_lading_required'
      | 'origin_photo_required'
      | 'pickup_notes'
    > &
      ScheduleValues &
      Omit<CargoValues, 'stops'>);

export type EstimateStopValues = {
  drag_id: string;
  address_name?: string | null;
} & PickOptional<
  MatchStopData,
  | 'destination_address'
  | 'destination_place_id'
  | 'po'
  | 'self_recipient'
  | 'signature_type'
  | 'signature_required'
  | 'signature_instructions'
  | 'delivery_notes'
  | 'destination_photo_required'
  | 'recipient'
> &
  PickRequired<MatchStopData, 'index'> &
  CargoStopValues;

export function isEstimateStopValues(
  values: unknown
): values is EstimateStopValues {
  return isObject(values, ['drag_id', 'items']);
}

export function isEstimateValues(values: unknown): values is EstimateValues {
  return isObject(values, ['box_truck_agreement', 'stops']);
}

function isRoute(values: unknown): values is EstimateValues {
  return isEstimateValues(values) ? values.stops.length > 1 : false;
}

function determinePreferredDriverId(
  match: Match | null,
  shipper: User | Shipper | undefined | null
) {
  if (shipper?.default_driver) {
    return shipper.default_driver.id;
  } else if (match?.preferred_driver) {
    return match.preferred_driver.id;
  } else {
    return undefined;
  }
}

export function buildEstimateStopValues(
  stop?: MatchStop,
  overrides?: Partial<EstimateStopValues>
): EstimateStopValues {
  return {
    id: stop?.id,
    address_name: stop?.destination_address?.name || null,
    drag_id: stop?.id || v4(),
    destination_address: stop
      ? stop?.destination_address?.formatted_address
      : '',
    destination_place_id: null,
    needs_pallet_jack: stop?.needs_pallet_jack || false,
    has_load_fee: stop?.has_load_fee || false,
    items: stop?.items.map(i => buildCargoStopItemValues(i)) || [],
    index: stop?.index || 0,
    po: stop?.po,
    ...overrides,
  };
}

export function buildEstimateValues(
  match: Match | null,
  overrides?: Partial<EstimateValues>
): EstimateValues {
  return {
    scheduled: match ? match.scheduled : false,
    pickup_at: match?.pickup_at || '',
    address_name: match?.origin_address?.name || null,
    dropoff_at: match?.dropoff_at || '',
    timezone: match?.timezone || moment.tz.guess(),
    vehicle_class_id: match?.vehicle_class.id,
    origin_address: match ? match?.origin_address?.formatted_address : '',
    origin_place_id: '',
    unload_method: match?.unload_method || undefined,
    box_truck_agreement: false,
    contract_id: match?.contract?.id,
    stops: match
      ? match.stops.map(stop => buildEstimateStopValues(stop))
      : [buildEstimateStopValues()],
    accessorials: match ? match.accessorials.map(a => ({ key: a.key })) : [],
    ...overrides,
  };
}

function totalDeclaredValueBelowLimit(
  stops: EstimateStopValues[],
  context: yup.TestContext<yup.AnyObject>
) {
  const state = store.getState();
  const vehicleClass = isEstimateValues(context.options.context)
    ? selectVehicleClass(state, context.options.context.vehicle_class_id)
    : undefined;

  const total = stops
    ?.flatMap(s => s.items)
    .reduce((sum, item) => {
      return sum + (item.declared_value || 0);
    }, 0);

  if (total && vehicleClass && total > vehicleClass.max_declared_value) {
    return context.createError({
      path: `declared_value`,
      message: `We can insure up to ${displayPrice(
        vehicleClass.max_declared_value
      )} in total for this vehicle class. If you need additional coverage contact our sales team.`,
    });
  }

  return true;
}

const estimateStopSchema: ObjectSchema<
  Omit<
    EstimateStopValues,
    | 'signature_required'
    | 'signature_type'
    | 'signature_instructions'
    | 'delivery_notes'
    | 'destination_photo_required'
    | 'recipient'
  >
> = cargoStopSchema.shape({
  po: yup.string().nullable(),
  address_name: yup.string().nullable(),
  drag_id: yup.string().required(),
  destination_address: yup.string().required('Destination address is required'),
  destination_place_id: yup.string().nullable(),
  index: yup.number().required(),
  self_recipient: yup.boolean(),
  items: yup
    .array()
    .required()
    .of(cargoStopItemSchema)
    .test(
      'requiredWhenRoute',
      'At least one item is required',
      (value, context) => {
        const values = context.options.context;
        if (isRoute(values)) {
          return !!value && value.length > 0;
        }

        return true;
      }
    ),
});

const accessorialSchema: ObjectSchema<MatchAccessorialData> = yup.object({
  key: yup.string().required(),
});

export const reviewSchema: ObjectSchema<
  Omit<
    EstimateValues,
    | 'sender'
    | 'platform'
    | 'po'
    | 'route_identifier'
    | 'bill_of_lading_required'
    | 'origin_photo_required'
    | 'pickup_notes'
    | 'box_truck_agreement'
    | 'self_sender'
    | 'accessorials'
    | 'contract_id'
    | 'scheduled'
    | 'pickup_at'
    | 'dropoff_at'
    | 'timezone'
  >
> = cargoSchema.shape({
  origin_address: yup.string().required('Origin address is required'),
  origin_place_id: yup.string().nullable(),
  address_name: yup.string().nullable(),
  stops: yup
    .array()
    .required()
    .of(estimateStopSchema)
    .min(1, 'A minimum of 1 stop is required')
    .test({
      name: 'totalDeclaredValueBelowLimit',
      test: totalDeclaredValueBelowLimit,
    }),
  preferred_driver_id: yup.string().notRequired(),
});

export const estimateSchema: ObjectSchema<
  Omit<
    EstimateValues,
    | 'sender'
    | 'platform'
    | 'po'
    | 'route_identifier'
    | 'bill_of_lading_required'
    | 'origin_photo_required'
    | 'pickup_notes'
  >
> = cargoSchema
  .shape({
    origin_address: yup.string().required('Origin address is required'),
    address_name: yup.string().nullable(),
    origin_place_id: yup.string().nullable(),
    box_truck_agreement: yup
      .boolean()
      .when('vehicle_class_id', ([vehicleClassId], schema) => {
        const vehicleClass = selectVehicleClass(
          store.getState(),
          vehicleClassId
        );

        if (vehicleClass?.type.legacy_id === 4)
          schema
            .required(
              'You must read & agree to the FRAYT LOGISTICS Broker Shipper Agreement'
            )
            .isTrue(
              'You must read & agree to the FRAYT LOGISTICS Broker Shipper Agreement'
            );

        return schema;
      }),
    self_sender: yup.boolean(),
    stops: yup
      .array()
      .required()
      .of(estimateStopSchema)
      .min(1, 'A minimum of 1 stop is required')
      .test({
        name: 'totalDeclaredValueBelowLimit',
        test: totalDeclaredValueBelowLimit,
      }),
    accessorials: yup.array().of(accessorialSchema),
    preferred_driver_id: yup.string().notRequired(),
    contract_id: yup.string().required('You must select a contract'),
  })
  .concat(matchScheduleSchema);

export default function Estimate(props: ShipTabProps) {
  const dispatch = useAppDispatch();
  const match = useSelector(selectEstimateMatch);
  const boxTruckAgreement = useSelector(selectEstimateBoxTruckAgreement);
  const user = useSelector(selectUser);
  const preferredDriverId = determinePreferredDriverId(match, user);
  const platform = preferredDriverId ? 'preferred_driver' : 'marketplace';
  const [initialValues, setInitialValues] = useState<EstimateValues>(
    buildEstimateValues(match, {
      box_truck_agreement: boxTruckAgreement,
      preferred_driver_id: preferredDriverId,
      platform: platform,
    })
  );
  const navigate = useNavigate();
  const prevMatch = usePrevious(match, null);
  const isDeliverPro = useSelector(selectPlatform) === 'deliver_pro';
  const deliverProRouteInsert = isDeliverPro ? '/deliverpro' : '';
  const [csvPreferredDriverId, setcsvPreferredDriverId] = useState<
    string | null | undefined
  >();

  const determinePlatform = (values: EstimateValues) => {
    if (!('preferred_driver_id' in values)) return undefined;
    return values.preferred_driver_id ? 'preferred_driver' : 'marketplace';
  };

  const handleSubmit = useCallback(
    async (
      values: EstimateValues,
      actions: FormikChangeHelpers<EstimateValues>
    ) => {
      try {
        if (match) {
          await dispatch(
            updateEstimate([
              match.id,
              {
                ...values,
                platform: determinePlatform(values),
              },
            ])
          ).unwrap();
        } else {
          const result = await dispatch(
            createEstimate({
              ...values,
              platform: determinePlatform(values),
            })
          ).unwrap();

          navigate(`${deliverProRouteInsert}/ship/${result.response.id}`);
        }
      } catch (error) {
        console.error('Error submitting estimate:', error);
      } finally {
        actions.setSubmitting(false);
      }
    },
    [dispatch, match, navigate, deliverProRouteInsert]
  );

  const validate = useCallback((values: EstimateValues) => {
    try {
      estimateSchema.validateSync(values, {
        abortEarly: false,
        context: values,
      });
    } catch (e) {
      return yupToFormErrors(e); // for rendering validation errors
    }
  }, []);

  useEffect(() => {
    dispatch(fetchFeatureFlag({ flag: 'preferred_driver' }));
    dispatch(fetchFeatureFlag({ flag: 'accessorials' }));
    dispatch(fetchFeatureFlag({ flag: 'show_dropoff_at' }));
    dispatch(fetchAccessorials());
    dispatch(fetchUserContracts());
  }, [user, dispatch]);

  useEffect(() => {
    if (prevMatch !== match) {
      const newValues = buildEstimateValues(match, {
        box_truck_agreement: boxTruckAgreement,
      });

      setInitialValues(newValues);
    }
  }, [match, prevMatch, boxTruckAgreement]);

  const onLoadCsv = useCallback(
    async (est: Partial<EstimateValues>) => {
      try {
        const estimate = buildEstimateValues(match, est);
        setInitialValues(estimate);
        setcsvPreferredDriverId(estimate.preferred_driver_id);
        await handleSubmit(estimate, {
          setSubmitting: () => {},
        });
      } catch (error) {
        console.error('Error loading CSV:', error);
      }
    },
    [handleSubmit, match]
  );

  const isEstimate = isMatchEstimate(match);

  return (
    <div className={props.showSideBar ? 'shrinkSize' : 'regularSize'}>
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleSubmit}
      >
        <>
          {isEstimate && <MatchCsvUploader onLoad={onLoadCsv} />}
          <EstimateForm
            {...props}
            handleSubmit={handleSubmit}
            csvPreferredDriverId={csvPreferredDriverId}
          />
        </>
      </Formik>
    </div>
  );
}
