import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Form, Formik, yupToFormErrors } from 'formik';
import { Col, Row } from 'react-flexbox-grid';
import { useAppDispatch } from '../../../../lib/store';
import { ShipTabKey, ShipTabProps } from '../../../../screens/ShipScreen';
import { Match } from '../../../../lib/actions/MatchAction';
import {
  authorizeEstimate,
  selectEstimateErrors,
  selectEstimateMatch,
  setPreferredDriverType,
  updateEstimate,
} from '../../../../lib/reducers/estimateSlice';
import { EstimateValues, buildEstimateValues, reviewSchema } from '../Estimate';
import { AddressFields } from './ReviewAddressFields';
import { OrderSummaryCard } from './ReviewOrderSummary';
import { ReviewSummaries } from './ReviewSummaries';
import StepFooter from '../../StepFooter';
import EstimateFormOnChange from '../../EstimateFormOnChange';
import Payment from '../../summary/PaymentSummary';

const MOBILE_BREAKPOINT = 767;

const checkForIncompleteAddresses = (match: Match) => {
  const incompleteOrigin = match.origin_address?.type !== 'address';
  const incompleteDestinations = match.stops?.some(
    stop => stop.destination_address?.type !== 'address'
  );

  return incompleteOrigin || incompleteDestinations;
};

interface InstructionsMessageProps {
  match: Match;
}

const InstructionsMessage: React.FC<InstructionsMessageProps> = ({ match }) => {
  const hasIncompleteAddresses = checkForIncompleteAddresses(match);

  if (!hasIncompleteAddresses) {
    return (
      <p className='u-push__bottom--lg addresses_message'>
        We now have everything we need, so review your details for accuracy then
        click the Ship button at the bottom of this page to place your order
        once you have confirmed the final estimate
      </p>
    );
  }

  return (
    <p className='u-push__bottom--lg addresses_message'>
      You will now need to enter any incomplete addresses. Your original
      estimate used partial addresses for the specified locations.
    </p>
  );
};

interface ReviewFormProps {
  match: Match;
  initialValues: EstimateValues;
  onSubmit: () => void;
  changeTab: (tab: ShipTabKey | -1) => void;
  estimateError: { message: string | null };
}

const ReviewForm: React.FC<ReviewFormProps> = ({
  match,
  initialValues,
  onSubmit,
  changeTab,
  estimateError,
}) => {
  const dispatch = useAppDispatch();
  const showSideBar = false;
  const initialOpen = window.innerWidth > MOBILE_BREAKPOINT;

  const handleFormChange = async (
    values: EstimateValues,
    actions: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      await dispatch(updateEstimate([match.id, values])).unwrap();
    } catch (error) {
      console.error('Error updating estimate:', error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={values => {
        try {
          reviewSchema.validateSync(values, {
            abortEarly: false,
            context: values,
          });
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      onSubmit={onSubmit}
    >
      {({ submitForm }) => (
        <Form>
          <EstimateFormOnChange
            onChange={(values: EstimateValues, _meta, actions) =>
              handleFormChange(values, actions)
            }
          />
          <AddressFields match={match} showSideBar={showSideBar} />
          <Row>
            <Col xs={12} md={showSideBar ? 12 : 6}>
              <OrderSummaryCard match={match} />
              <Payment
                initialIsOpen={initialOpen}
                onReview={() => changeTab(ShipTabKey.Payment)}
              />
            </Col>
            <ReviewSummaries
              match={match}
              initialOpen={initialOpen}
              changeTab={changeTab}
            />
          </Row>
          <StepFooter
            onPrev={() => changeTab(-1)}
            onNext={submitForm}
            nextLabel='Authorize'
            isForm={false}
            error={estimateError?.message}
          />
        </Form>
      )}
    </Formik>
  );
};

const Review: React.FC<ShipTabProps> = ({ changeTab }) => {
  const match = useSelector(selectEstimateMatch);
  const estimateError = useSelector(selectEstimateErrors);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleAuthorize = async () => {
    if (!match) return;

    try {
      await dispatch(authorizeEstimate(match.id)).unwrap();
      sessionStorage.removeItem('weightInfo');
      dispatch(setPreferredDriverType(0));
      navigate('/matches/' + match.id);
    } catch (error) {
      console.error('Failed to authorize estimate:', error);
    }
  };

  if (!match) {
    return null;
  }

  const initialValues = buildEstimateValues(match);

  return (
    <div>
      <h1 className='u-push__bottom--md u-push__top--none'>Review Match</h1>
      <InstructionsMessage match={match} />
      <br />
      <ReviewForm
        match={match}
        initialValues={initialValues}
        onSubmit={handleAuthorize}
        changeTab={changeTab}
        estimateError={estimateError}
      />
    </div>
  );
};

export default Review;
