import React from 'react';
import { Match } from '../../../../lib/actions/MatchAction';
import { renderPricing } from '../../PriceBreakdown';
import moment from 'moment';

interface OrderSummaryCardProps {
  match: Match;
}

const HeaderCell: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => (
  <div className='header_cell'>
    <span className='header_first'>{label}</span>
    <span className='header_second'>{value}</span>
  </div>
);

const FeesList: React.FC<{ fees: Match['fees'] }> = ({ fees }) => (
  <>
    {fees?.map((item, index) => (
      <React.Fragment key={item.id}>
        <div className='card_header'>
          <div className='header_cell'>
            <span className='header_second'>{item.name}</span>
          </div>
          <div className='header_cell'>
            {renderPricing(
              item.amount,
              item.original_amount,
              '',
              'header_second'
            )}
          </div>
        </div>
        {index !== fees.length - 1 && <div className='divider' />}
      </React.Fragment>
    ))}
  </>
);

export const OrderSummaryCard: React.FC<OrderSummaryCardProps> = ({
  match,
}) => {
  const orderDate = moment(match.inserted_at).format('MMM D, YYYY');

  return (
    <div className='estimate_card'>
      <div className='card_header'>
        <HeaderCell label='Order Date' value={orderDate} />
        <HeaderCell label='Match ID' value={match.shortcode} />
        <HeaderCell label='Vehicle' value={match.vehicle_class.type.name} />
      </div>
      <div className='divider' />
      <FeesList fees={match.fees} />
      <div className='card_bottom card_header'>
        <div className='header_cell'>
          <span className='bottom_cell_label'>Total</span>
        </div>
        <div className='header_cell'>
          <span className='bottom_cell_label'>${match.total_price}</span>
        </div>
      </div>
    </div>
  );
};
