import React from 'react';
import moment, { Moment, MomentInput } from 'moment';
import 'moment-timezone';
import { User, getPreferredDriverOptionsBy } from './actions/UserAction';
import {
  Coupon,
  Fee,
  Match,
  MatchState,
  MatchStop,
  MatchStopState,
} from './actions/MatchAction';
import { Intent } from '@blueprintjs/core';
import { getErrorMessage } from './FraytRequest';
import { PublicMatch, PublicMatchStop } from './actions/MatchStopAction';

export const humanize = (s: string) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : ' ' + d.toUpperCase()
  );

export function formatMID(MID?: string) {
  // Format the Match ID correctly. 8 digit limit, uppercase. If undefined, return blank to prevent error.
  return MID ? MID.toUpperCase().substring(0, 8) : '';
}

export function formatDistance(mileage?: number) {
  // Format the mileage and return it as a string.
  return mileage ? mileage.toFixed(1) + ' mi' : '';
}

export function formatAddress(address = 'Debug City, OH') {
  // Do any formatting needed to display US addresses. Currently, this only involves taking out the ", USA" at the end that is automatically added in geocoded addresses.
  return address ? address.replace(', USA', '') : 'N/A';
}

export function dateWithTZ(date: MomentInput, timezone?: string | null) {
  const m = moment.utc(date);

  return m.tz(timezone || moment.tz.guess());
}

export function nearestFutureStep(date: Moment, stepSize: number) {
  const minuteStep = stepSize / 60;
  const remainder = minuteStep - (date.minute() % minuteStep);
  return moment(date).add(remainder).second(0);
}

export function formatDate(date: string | number | Date) {
  const newDate = new Date(date).getTime();
  const currentDate = new Date().getTime();
  // Format 30 days in the past
  const timestamp = currentDate - 14 * 24 * 60 * 60 * 1000;
  //                             day hour  min  sec  msec
  if (newDate > timestamp) {
    return moment(newDate).fromNow();
  } else {
    return moment(newDate).format('MM/DD/YY');
  }
}

// This function is for taking a date and formatting it to be shown specifically for pickup and dropoff times
export function formatDateScheduling(
  date: MomentInput,
  timezone?: string | null
) {
  if (!date) {
    return <span>ASAP</span>;
  } else {
    const datetime = dateWithTZ(date, timezone);
    return (
      <>
        {datetime.format('MMMM D, h:mm A ')}
        <strong>{datetime.format('z')}</strong>
      </>
    );
  }
}

export function formatDateVerbose(date: MomentInput, timezone: string | null) {
  return dateWithTZ(date, timezone).format('MMMM D, YYYY');
}

export function formatDateSuccinct(date: MomentInput, timezone: string | null) {
  return dateWithTZ(date, timezone).format('MMM D, YYYY');
}

export function formatDateTime(datetime: MomentInput, timezone: string | null) {
  return dateWithTZ(datetime, timezone).format('MMM D, YYYY h:mm A z');
}

export function formatDateTimeRange(
  start: MomentInput,
  end: MomentInput,
  timezone: string | null
) {
  const startDate = dateWithTZ(start, timezone);
  const endDate = dateWithTZ(end, timezone);

  if (startDate.isSame(endDate, 'day'))
    return `${startDate.format('MMM D, YYYY h:mm A')} - ${endDate.format(
      'h:mm A z'
    )}`;

  return `${startDate.format('MMM D, YYYY h:mm A')} - ${endDate.format(
    'MMM D, YYYY h:mm A z'
  )}`;
}

export function formatTime(time: MomentInput, timezone: string | null) {
  return dateWithTZ(time, timezone).format('h:mm A z');
}

export function formatMatchStatus(match: Match | PublicMatch) {
  // Handle singlestop Marketplace match with returned stop
  if (
    'stops' in match &&
    match.stops.length === 1 &&
    match.stops[0]?.state === 'returned'
  ) {
    return 'Returned';
  }

  // Handle preferred driver platforms
  if (
    'platform' in match &&
    (match.platform === 'deliver_pro' || match.platform === 'preferred_driver')
  ) {
    if (match.state === 'offered') {
      return 'Awaiting Preferred Driver';
    } else if (match.state === 'accepted') {
      return 'Preferred Driver Accepted';
    } else if (
      match.state === 'offer_not_accepted' &&
      match.platform === 'preferred_driver'
    ) {
      return 'Preferred Driver Not Available';
    }
  }

  // Handle common states for all match types
  switch (match.state) {
    case 'offered':
    case 'offer_not_accepted':
      return 'Assigning Driver';
    case 'accepted':
      return 'Driver Accepted';
    case 'admin_canceled':
      return 'Canceled';
    case 'arrived_at_pickup':
      return 'Arrived at Pickup Location';
    case 'unable_to_pickup':
      return 'Failed pickup';
    case 'completed':
    case 'charged':
      return 'Delivered';
    default:
      return humanize(match.state);
  }
}

export function formatMatchStopStatus(stop: MatchStop) {
  return humanize(stop.state);
}

export function formatPublicMatchStopStatus(stop: PublicMatchStop) {
  // handle returning states, only if their stop is the one being returned
  if (
    stop.state === 'undeliverable' &&
    ['en_route_to_return', 'arrived_at_return'].includes(stop.match.state)
  )
    return 'Failed Delivery';

  // use match status if their stop isn't in progress yet
  if (stop.state === 'pending') return formatMatchStatus(stop.match);

  // otherwise use the stop status
  switch (stop.state) {
    case 'undeliverable':
      return 'Failed Delivery';
    case 'en_route':
      return 'En Route to Dropoff';
    case 'arrived':
      return 'Arrived at Dropoff';
    default:
      return humanize(stop.state);
  }
}

export function formatMatchProgress({ state }: PublicMatch): {
  intent: Intent;
  value: number;
  stripes: boolean;
} {
  const states = Object.values(MatchState);
  const index = states.indexOf(state);
  const value = (index - 3) / (states.length - 4); // 0.142857143 is 1/7th; there are 7 possible stages to display on the progress bar
  let intent: Intent = Intent.DANGER;
  let stripes = false;

  switch (state) {
    case 'canceled':
    case 'admin_canceled':
      intent = Intent.WARNING;
      break;
    case 'completed':
    case 'charged':
      intent = Intent.SUCCESS;
      break;
    case 'unable_to_pickup':
      intent = Intent.WARNING;
      break;
    default:
      intent = Intent.PRIMARY;
      stripes = true;
      break;
  }

  return { intent, value, stripes };
}

export function formatMatchStopProgress({ state }: PublicMatchStop): {
  intent: Intent;
  value: number;
  stripes: boolean;
} {
  const states = Object.values(MatchStopState);
  const index = states.indexOf(state);
  const value = (index - 1) / (states.length - 3);
  let intent: Intent = Intent.DANGER;
  let stripes = false;

  switch (state) {
    case 'unserved':
    case 'undeliverable':
    case 'returned':
      intent = Intent.WARNING;
      break;
    case 'delivered':
      intent = Intent.SUCCESS;
      break;
    default:
      intent = Intent.PRIMARY;
      stripes = true;
      break;
  }

  return { intent, value, stripes };
}

export function formatFullMatchStopState(stop: PublicMatchStop) {
  const { match } = stop;
  const stopIsActive = match.state === 'picked_up' && stop.state !== 'pending';

  const progress = stopIsActive
    ? formatMatchStopProgress(stop)
    : formatMatchProgress(match);

  progress.value = progress.value / 2;

  if (stopIsActive) progress.value += 0.5;

  return progress;
}

export function formatVolume(volume?: number | null, decimals = 0) {
  const multiplier = Math.pow(10, decimals);
  return volume ? Math.ceil((volume / 1728) * multiplier) / multiplier : 0;
}

export function formatVehicleImage(vehicleTypeKey: string, selected?: boolean) {
  const color = selected ? 'blue' : 'gray';

  return `/img/vehicles/${color}/${vehicleTypeKey}.png`;
}

export function formatCurrentStateTime(match: Match): React.ReactNode {
  let state;
  let timestamp;

  switch (match.state) {
    case 'canceled':
    case 'admin_canceled':
      state = 'Canceled on';
      timestamp = match.canceled_at;
      break;
    case 'charged':
    case 'completed':
      state = 'Completed on';
      timestamp = match.completed_at;
      break;
    case 'accepted':
      state = 'Accepted on';
      timestamp = match.accepted_at;
      break;
    case 'assigning_driver':
      state = 'Activated on';
      timestamp = match.activated_at;
      break;
    case 'scheduled':
      state = 'Scheduled for';
      timestamp = match.picked_up_at;
      break;
  }

  if (state) {
    return (
      <>
        {state + ' '}
        {timestamp ? <br /> : null}
        {timestamp ? formatDateTime(timestamp, match.timezone) : null}
      </>
    );
  }

  return 'N/A';
}

export function formatPhoneNumber(phoneNumber: string) {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

export function formatPhoneNumberSimplify(phoneNumber: string) {
  const cleaned = ('' + phoneNumber).replace(/-|\s/g, '');
  return cleaned;
}

export function matchInState(match: PublicMatch, states: MatchState[]) {
  return states.includes(match.state);
}

export function centsToDollars(price: number) {
  return price / 100;
}

export function totalEstimatePrice(fees: Fee[]) {
  if (!fees) return 0;
  const priceInCents = fees.reduce((acc, fee) => acc + fee.amount, 0);
  return centsToDollars(priceInCents);
}

export function getSubtotal(fees: Fee[]) {
  return centsToDollars(fees.reduce((total, fee) => total + fee.amount, 0));
}

export function getFee(fees: Fee[], type: string) {
  if (fees) {
    const fee = fees.find(fee => fee.type === type);
    if (fee) {
      return centsToDollars(fee.amount);
    }
  }

  return 0;
}

export function findFee(fees: Fee[], type: string) {
  return fees?.find(fee => fee.type === type);
}

export function displayFee(fees: Fee[], type: string) {
  return '$' + getFee(fees, type).toFixed(2);
}

export function displayPrice(price: number, isCents = true) {
  price = isCents ? centsToDollars(price) : price;
  return '$' + price.toFixed(2);
}

export function getDiscount(fees: Fee[], coupon: Coupon | null) {
  const base_fee = getFee(fees, 'base_fee');
  return coupon && coupon.percentage ? coupon.percentage * 0.01 * base_fee : 0;
}

export const getUserCompany = (user: User | null) => {
  return typeof user?.company === 'string' ? undefined : user?.company;
};

export const getLocationOptions = (user: User) => {
  const locations = getUserCompany(user)?.locations || [];

  return locations.map(({ name, id }) => ({
    label: name,
    value: id,
  }));
};

export function chunkArray<T = unknown>(array: T[], perChunk: number) {
  return array.reduce<T[][]>((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex]?.push(item);

    return resultArray;
  }, []);
}

export function shallowEqual<T extends Record<string, unknown>>(
  object1: T,
  object2: T,
  ignore: (keyof T)[] = []
) {
  for (const key of ignore) {
    delete object1[key];
    delete object2[key];
  }

  const keys1 = Object.keys(object1) as (keyof typeof object1)[];
  const keys2 = Object.keys(object2) as (keyof typeof object2)[];
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
}

export function safeToNumber(str: string, fallback = 0) {
  const number = parseFloat(str);

  return isNaN(number) ? fallback : number;
}

export function removeEmptyAttrs(obj: { [key: string]: unknown }) {
  (Object.keys(obj) as Array<keyof typeof obj>).forEach(
    k => obj[k] == null && delete obj[k]
  );

  return obj;
}

export function removeDuplicates<T = unknown>(
  arr: T[],
  by = (i: T): unknown => i
) {
  arr = [...arr].reverse();
  const byArr = arr.map(by);

  return arr.filter((i, index) => byArr.indexOf(by(i)) === index);
}

type ObjectWithKeys<K extends string> = {
  [Key in K]: unknown;
};

export function isObject<T extends string>(
  object: unknown,
  keys?: T[]
): object is ObjectWithKeys<T> {
  return (
    typeof object === 'object' &&
    object !== null &&
    (!keys || keys.every(key => key in object))
  );
}

export function getEnRouteStop(match: Match) {
  const { state, stops } = match;
  if (state === 'picked_up')
    return stops.find(stop => stop.state === 'en_route') || null;

  return null;
}

export function isMatchEtaActive(match: PublicMatch) {
  return ['en_route_to_pickup', 'en_route_to_return'].includes(match.state);
}

export function getActiveMatchStopEta(stop: PublicMatchStop) {
  const { match } = stop;

  if (isMatchEtaActive(match)) return match.eta;

  if (match.state === 'picked_up' && stop.state === 'en_route') return stop.eta;

  return null;
}

export function getActiveEta(match: Match) {
  const { state, eta } = match;
  if (isMatchEtaActive(match)) return eta;

  if (state === 'picked_up') {
    const stop = getEnRouteStop(match);

    if (stop) return stop.eta;
  }

  return null;
}

const hiddenMatchStates: MatchState[] = [
  MatchState.Offered,
  MatchState.Pending,
  MatchState.Scheduled,
];

const editableMatchStates: MatchState[] = [
  ...hiddenMatchStates,
  MatchState.AssigningDriver,
  MatchState.Inactive,
];

const semiEditableMatchStates: MatchState[] = [
  ...editableMatchStates,
  MatchState.OfferNotAccepted,
  MatchState.Accepted,
  MatchState.EnRouteToPickup,
  MatchState.ArrivedAtPickup,
];

export function canAccessMatch(match: Match, user: User | null) {
  if (!match.shipper) return true;
  if (!user) return false;
  if (user.id === match.shipper.id) return true;
  if (!user.location || !match.shipper.location) return false;
  if (user.location.id === match.shipper.location.id) return true;
  return (
    user.role === 'company_admin' &&
    user.location.company_id === match.shipper.location.company_id
  );
}

export function isMatchEditable(
  match: Match,
  user: User | null,
  liveEnabled: boolean
) {
  const isEditableState = liveEnabled
    ? semiEditableMatchStates.includes(match.state)
    : editableMatchStates.includes(match.state);

  const hasPermissions = canAccessMatch(match, user);

  return isEditableState && hasPermissions;
}

export function isMatchCancelable(match: Match) {
  return match.contract.allowed_cancellation_states.includes(match.state);
}

export function isMatchVisibleToDriver(match: Match | null) {
  return !!match && !hiddenMatchStates.includes(match.state);
}

export function isMatchFullyEditable(match: Match | null) {
  return !match || editableMatchStates.includes(match.state);
}

export function isMatchEstimate(match: Match | null) {
  return !match || ['pending', 'inactive'].includes(match.state);
}

export function nextLabel(isEstimate: boolean) {
  return (isTouched: boolean) =>
    isEstimate ? undefined : isTouched ? 'Save & Continue' : 'Continue';
}

export function isMatchPickupActive(match: Match | PublicMatch) {
  return ['en_route_to_pickup', 'arrived_at_pickup'].includes(match.state);
}

export function isMatchPickupCompleted(match: Match | PublicMatch) {
  return ['picked_up', 'completed'].includes(match.state);
}

export function isMatchStopActive(stop: MatchStop | PublicMatchStop) {
  return ['en_route', 'arrived', 'signed'].includes(stop.state);
}

export function isMatchStopCompleted(stop: MatchStop | PublicMatchStop) {
  return ['delivered', 'returned'].includes(stop.state);
}

const checkIfDriverRejected = async (type: 'email' | 'name', query: string) => {
  const {
    data: { data: unfilteredOptions },
  } = await getPreferredDriverOptionsBy({ type, query });

  if (unfilteredOptions.length > 0) {
    const existsInUnfiltered =
      unfilteredOptions[0]?.email === query ||
      unfilteredOptions[0]?.first_name
        .toLowerCase()
        .includes(query.toLowerCase());

    if (existsInUnfiltered) {
      throw new Error(
        `FRAYT driver has already rejected the match and cannot be resent the match. Please try a different ${type}.`
      );
    }
  }
};

export const handleDriverSelectSubmit = async (
  type: 'email' | 'name',
  query: string | undefined,
  setFieldError: (field: string, message: string | undefined) => void,
  for_match?: Match
) => {
  if (!query) return undefined;

  try {
    const matchId = for_match ? for_match.id : '';
    const {
      data: { data: preferredDriverOptions },
    } = await getPreferredDriverOptionsBy({
      type,
      query,
      for_match: matchId,
    });

    if (preferredDriverOptions.length <= 0) {
      if (for_match) {
        await checkIfDriverRejected(type, query);
      }
      throw new Error(
        `No FRAYT driver found with that ${type}. Please try a different ${type}.`
      );
    }

    return preferredDriverOptions;
  } catch (e) {
    setFieldError(type, getErrorMessage(e));
    return undefined;
  }
};
