import Axios, { AxiosResponse } from 'axios';
import { buildHeaders, buildUrl } from '../FraytRequest';
import { Address } from './UserAction';
import {
  ETA,
  MatchSLA,
  MatchState,
  MatchStopState,
  VehicleClass,
} from './MatchAction';

export type PublicMatchStopResponse =
  AxiosResponse<PublicMatchStopResponseData>;

export type PublicMatchStopResponseData = {
  response: PublicMatchStop;
};

export async function getMatchStop(
  matchStopId: string,
  token: string | null
): Promise<PublicMatchStopResponse> {
  const headers = buildHeaders();
  const url = buildUrl(`public/match_stops/${matchStopId}?token=${token}`);

  return Axios.get(url, { headers });
}

export type PublicMatchStop = {
  id: string;
  po: string | null;
  eta: ETA | null;
  state: MatchStopState;
  destination_address: Address;
  match: PublicMatch;
  delivered_at: string | null;
  undeliverable_at: string | null;
};

export type PublicMatch = {
  id: string;
  po: string | null;
  shortcode: string;
  state: MatchState;
  timezone: string | null;
  eta: ETA | null;
  vehicle_class: VehicleClass;
  origin_address: Address;
  inserted_at: string;
  picked_up_at: string | null;
  activated_at: string | null;
  accepted_at: string | null;
  completed_at: string | null;
  canceled_at: string | null;
  slas: MatchSLA[];
  driver_slas: MatchSLA[];
};
