import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Label } from '@blueprintjs/core';
import { PlacesSearch } from '../../../form/PlacesSearch';
import FieldError from '../../../form/FieldError';
import { Match, MatchStop } from '../../../../lib/actions/MatchAction';

interface AddressFieldsProps {
  match: Match;
  showSideBar: boolean;
}

const StopAddressField: React.FC<{ stop: MatchStop; totalStops: number }> = ({
  stop,
  totalStops,
}) => {
  const name = `stops[${stop.index}]`;

  return (
    <Label>
      {totalStops > 1 ? `STOP #${stop.index + 1}` : 'DROPOFF'}
      <PlacesSearch
        name={`${name}.destination_address`}
        placeName={`${name}.destination_place_id`}
        icon='arrow-down'
        requireFullAddress={true}
      />
      <p style={{ color: 'red' }}>
        Original: {stop.destination_address.formatted_address}
      </p>
      <FieldError name={`${name}.destination_address`} />
    </Label>
  );
};

export const AddressFields: React.FC<AddressFieldsProps> = ({
  match,
  showSideBar,
}) => {
  const incompleteOrigin = match.origin_address?.type !== 'address';
  const incompleteStops = match.stops?.filter(
    stop => stop.destination_address?.type !== 'address'
  );

  if (!incompleteOrigin && !incompleteStops?.length) {
    return null;
  }

  return (
    <Row>
      <Col xs={12} md={showSideBar ? 12 : 6}>
        {incompleteOrigin && (
          <Label>
            PICKUP
            <PlacesSearch
              name='origin_address'
              placeName='origin_place_id'
              icon='arrow-up'
              requireFullAddress={true}
            />
            <p style={{ color: 'red' }}>
              Original: {match.origin_address.formatted_address}
            </p>
            <FieldError name='origin_address' />
          </Label>
        )}
      </Col>
      <Col xs={12} md={showSideBar ? 12 : 6}>
        {incompleteStops?.map(stop => (
          <StopAddressField
            key={stop.id}
            stop={stop}
            totalStops={match.stops?.length || 0}
          />
        ))}
      </Col>
    </Row>
  );
};
