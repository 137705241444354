import { FormEvent, useState } from 'react';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { Button } from '@blueprintjs/core';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import {
  createStripeToken,
  selectCreditCardError,
  selectCreditCardStatus,
  selectUser,
  updateCreditCard,
} from '../../lib/reducers/userSlice';
import { useAppDispatch } from '../../lib/store';

type CheckoutFormProps = {
  elements: StripeElements | null;
  stripe: Stripe | null;
} & InjectedCheckoutFormProps;

function CheckoutForm({
  elements,
  stripe,
  cancelEditCreditCard,
}: CheckoutFormProps) {
  const dispatch = useAppDispatch();
  const errorMessage = useSelector(selectCreditCardError);
  const [success, setSuccess] = useState(false);
  const user = useSelector(selectUser);
  const status = useSelector(selectCreditCardStatus);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (user && stripe && cardElement) {
      try {
        const token = await dispatch(
          createStripeToken({
            stripe: stripe,
            cardElement: cardElement,
            user: user,
          })
        ).unwrap();

        if (!token.card) {
          throw new Error('Credit card is required');
        }

        await dispatch(
          updateCreditCard({
            stripe_token: token.id,
            stripe_card: token.card.id,
          })
        ).unwrap();

        setSuccess(true);
        cancelEditCreditCard();
      } catch (err) {
        setSuccess(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className='StripeForm'>
      <label>
        <div style={{ marginBottom: 8 }}>
          Card details for {user?.first_name}
        </div>
        <CardElement options={{ style: { base: { fontSize: '18px' } } }} />
      </label>
      {errorMessage && (
        <div className='formError' style={{ marginTop: 10 }}>
          {errorMessage}
        </div>
      )}
      <div style={{ display: 'flex', marginTop: 21 }}>
        <Button
          icon={'undo'}
          text='Cancel'
          large
          fill
          className={'nextStep warningButton'}
          onClick={cancelEditCreditCard}
          style={{ marginRight: 10, flex: 1 }}
        />
        <Button
          icon={success ? 'tick-circle' : 'tick'}
          text={success ? 'Updated Card!' : 'Update Card'}
          loading={status === 'loading'}
          disabled={status === 'loading'}
          large
          fill
          type='submit'
          className={'nextStep-CCardAdd'}
          style={{ marginLeft: 10, flex: 2 }}
        />
      </div>
    </form>
  );
}

type InjectedCheckoutFormProps = {
  cancelEditCreditCard: () => void;
};

const InjectedCheckoutForm = (props: InjectedCheckoutFormProps) => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <CheckoutForm elements={elements} stripe={stripe} {...props} />
      )}
    </ElementsConsumer>
  );
};

export default InjectedCheckoutForm;
