import React from 'react';
import { Col } from 'react-flexbox-grid';
import { Match } from '../../../../lib/actions/MatchAction';
import { ShipTabKey } from '../../../../screens/ShipScreen';
import EstimateSummary from '../../summary/EstimateSummary';
import DeliverySummary from '../../summary/DeliverySummary';
import CargoSummary from '../../summary/CargoSummary';

interface ReviewSummariesProps {
  match: Match;
  initialOpen: boolean;
  changeTab: (tab: ShipTabKey) => void;
}

export const ReviewSummaries: React.FC<ReviewSummariesProps> = ({
  match,
  initialOpen,
  changeTab,
}) => (
  <Col xs={12} md={6}>
    <EstimateSummary
      match={match}
      initialIsOpen={initialOpen}
      onReview={() => changeTab(ShipTabKey.Estimate)}
      logistics={true}
    />
    <DeliverySummary
      match={match}
      initialIsOpen={initialOpen}
      onReview={() => changeTab(ShipTabKey.Delivery)}
    />
    <CargoSummary
      match={match}
      initialIsOpen={initialOpen}
      onReview={() => changeTab(ShipTabKey.Cargo)}
    />
  </Col>
);
